<template>
  <div class="container">
    <div class="transcript-for-group mt-4">
      <h4 style="text-align: center">Транскрипт для групп</h4>


      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Курс</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyCourse">
            <option v-for="(sCourse, sCourseIndex) in [{value: 0, name:'Выберите курс'},...studyCourses]"
                    :key="sCourseIndex"
                    :value="sCourse.value">
              {{ sCourse.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Уровень обучения</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyLevelId">
            <option
                v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...docOrdersStudents_form.studyLevels]"
                :value="item.id"
                :key="index">{{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4" v-if="studyCourse&&studyLevelId">
        <label class="col-md-3 col-form-label">Образовательная программа</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="educationProgramId">
            <option
                v-for="(item, index) in [{id: 0, education_speciality_name: 'Выберите ОП', language_name: 'язык', admission_year:'год' }, ...docOrdersStudents_form.educationPrograms]"
                :value="item.id"
                :key="index">
              {{ item.education_speciality_code }} {{ item.education_speciality_name }} -
              {{ item.language_name }} - {{ item.admission_year }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4" v-if="educationProgramId != 0">
        <div class="col-md-3">Группы</div>
        <div class="col-md-9" v-if="!loadingGroup">
          <div v-if="docOrdersStudents_form.studentGroups.length">
            <select class="form-control form-select" v-model="groupId">
              <option
                  v-for="(item, index) in [{id: 0, name: 'Выберите группу'}, ...docOrdersStudents_form.studentGroups]"
                  :value="item.id"
                  :key="index">{{ item.name }}
              </option>
            </select>
          </div>
          <div v-else class="text-danger">
            В данной образовательной программе нет групп
          </div>
        </div>
      </div>

      <div class="row mt-4" v-if="groupId != 0">
        <div class="col-md-3">Семестр</div>
        <div class="col-md-9">


          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="semester1" value="1" v-model="checkedSemesters">
            <label class="form-check-label" for="semester1">1</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="semester2" value="2" v-model="checkedSemesters">
            <label class="form-check-label" for="semester2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="semester3" value="3" v-model="checkedSemesters">
            <label class="form-check-label" for="semester3">3</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="semester4" value="4" v-model="checkedSemesters">
            <label class="form-check-label" for="semester4">4</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="semester5" value="5" v-model="checkedSemesters">
            <label class="form-check-label" for="semester5">5</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="semester6" value="6" v-model="checkedSemesters">
            <label class="form-check-label" for="semester6">6</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="semester7" value="7" v-model="checkedSemesters">
            <label class="form-check-label" for="semester7">7</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="semester8" value="8" v-model="checkedSemesters">
            <label class="form-check-label" for="semester8">8</label>
          </div>

        </div>
      </div>


      <div class="mt-4 text-center" v-if="checkedSemesters.length != 0">
        <a class="btn btn-primary" target="_blank" role="button" style="margin-right: 5px"
           :href="`https://back.uib.kz/student/transcript/print-transcript-by-semester?group_id=${groupId}&semester=${checkedSemesters}`">
          Распечатать
        </a>


        <Button label="Очистить" class="p-button-warning" @click="clear"/>

      </div>




    </div>
  </div>


</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "TranscriptForGroup",
  data() {
    return {
      studyLevelId: 0,
      studyCourse: 0,
      educationProgramId: 0,
      groupId: 0,
      loadingGroup: false,
      checkedSemesters: [],
      studyCourses: [
        {
          value: 1,
          name: '1'
        },
        {
          value: 2,
          name: '2'
        },
        {
          value: 3,
          name: '3'
        },
        {
          value: 4,
          name: '4'
        },
      ],
    }
  },
  computed: {
    ...mapState('docOrdersStudents', ['docOrdersStudents_form']),
  },
  watch: {
    studyCourse() {
      this.getEducationPrograms()
    },
    studyLevelId() {
      this.getEducationPrograms()
    },
    async educationProgramId(id) {
      this.loadingGroup = true
      await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_ID(id)
      this.loadingGroup = false
    }
  },
  methods: {
    ...mapActions('docOrdersStudents', ['GET_STUDY_LEVELS', 'GET_EDUCATION_PROGRAMS', 'GET_STUDENTS_BY_EDUCATION_PROGRAM_ID']),
    getEducationPrograms() {
      if (this.studyLevelId && this.studyCourse) {
        this.GET_EDUCATION_PROGRAMS({studyLevelId: this.studyLevelId, studyCourse: this.studyCourse})
      }
    },
    clear() {
      this.studyLevelId = 0
      this.studyCourse = 0
      this.educationProgramId = 0
      this.groupId = 0
      this.checkedSemesters = []
    }
  },
  async mounted() {
    await this.GET_STUDY_LEVELS()
  }
}
</script>

<style scoped>


</style>